import routes from '@reveel/helpers/routes';
import axios from 'axios';
import { useQueryClient } from 'react-query';
import { useMutation } from 'react-query';
import { useState } from 'react';
import { friendsKey } from './useFriendShips';
import track from '../../analytics';

export const useInviteFriends = ({ onSubmit }: { onSubmit: () => void }) => {
  const [emails, setEmails] = useState<string[]>([]);
  const [status, setStatus] = useState<string>('')
  
  const [isLoading, setIsLoading] = useState(false);

  const queryClient = useQueryClient();
  const route = routes.api.user.friends.inviteEmail;
  const mutation = useMutation(
    async () => {
      setIsLoading(true);
      axios.post(route, { emails });
      track('Sent Invitation', {
        channel: 'email',
        invited_to: 'network',
        invitations_count: emails.length,
      })
      setStatus('success');
      onSubmit && setTimeout(onSubmit, 3000);
    },
    {
      onMutate: async (data) => {
        // await queryClient.cancelQueries(key)
        // const previousValue = queryClient.getQueryData(key)
        // queryClient.setQueryData(key, )
        setIsLoading(true);
        // return previousValue
      },
      onError: (err, variables, previousValue) => {
        // queryClient.setQueryData(key, previousValue)
        setIsLoading(false);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(friendsKey)
        setIsLoading(false);
      },
    }
  );

  return {
    onSubmit: mutation.mutate,
    setEmails,
    emails,
    isLoading,
    status,
  };
};
